<template>
  <div>
    <van-nav-bar title="登录" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <van-cell-group>
        <van-field v-model="phone" label="手机号" />
        <van-field v-model="auth_code" label="验证码">
          <van-button slot="button" size="small" type="primary" @click="getAuthCode">{{ buttonText }}</van-button>
        </van-field>
        <van-button type="default" class="submit" @click="login" block style="margin-top: 20px">登录</van-button>
        <div class="tips">全国服务热线：400-000-6666（周一至周日 8:00-22:00）</div>
      </van-cell-group>
      <van-dialog v-model="showCaptchaDialog" title="输入验证码" show-cancel-button>
        <van-field v-model="captcha" placeholder="请输入图形验证码" />
        <van-button type="default">获取验证码</van-button>
      </van-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      showCaptchaDialog: false,
      captcha: null,
      phone: null,
      auth_code: null,
      buttonText: '获取验证码',
      redirectUrl: this.$route.query.redirect
    }
  },
  methods: {
    login () {
      this.$http.post('/customers/login', { phone: this.phone, code: this.auth_code }).then((res) => {
        localStorage.setItem('amafit-token', res.data.token)
        localStorage.setItem('customer-phone', this.phone)
        this.$http.defaults.headers.common['X-Access-Token'] = res.data.token
        if (this.redirectUrl) {
          this.$router.replace(this.redirectUrl)
        } else {
          this.$router.replace('/services/repair-list')
        }
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    randomCode () {
      return Math.random().toString().replace('0.', '')
    },
    getAuthCode () {
      this.$http.post('https://sale.huami.com/v1/auth-codes', { phone: this.phone }).then((res) => {
        this.buttonText = 60
        this.timer = setInterval(() => {
          this.buttonText--

          if (this.buttonText === 0) {
            clearInterval(this.timer)
            this.buttonText = '获取验证码'
          }
        }, 1000)
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    }
  }
}
</script>

<style lange="scss" scoped>
.tips{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 60px;
}
</style>
